// TODO: better explanation
// wraps an async function in such a way, that it only allows you to run a single instance of it concurrently
export function preventConcurrency<T extends (...args: any[]) => Promise<any>>(f: T): T {
  let running: Promise<any> | undefined;
  return (async (...args: any[]): Promise<any> => {
    if (running) return running;
    running = f(...args);
    try {
      const result = await running;
      return result;
    } finally {
      running = undefined;
    }
  }) as T;
}
