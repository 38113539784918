import React from "react";
import find from "lodash/find";
import isNumber from "lodash/isNumber";
import { Environment } from "@trunkery/internal/lib/environment";
import { defaultCurrencyInfo, formatCurrency } from "@lana-commerce/core/formatCurrency";

export interface MoneyProps {
  value: number;
  currency: string;
  explicitSign?: boolean;
  env: Environment;
}

export class Money extends React.Component<MoneyProps> {
  render() {
    if (!isNumber(this.props.value) || isNaN(this.props.value)) {
      return <span>—</span>;
    }
    const c = find(this.props.env.currencies, (c) => c.currency.code === this.props.currency);
    return (
      <span>
        {formatCurrency(c || defaultCurrencyInfo, this.props.value, { explicitSign: this.props.explicitSign })}
      </span>
    );
  }
}
